angular.module('app.controllers').controller('ProductModalCtrl', ['$scope', '$rootScope','$sce', (self, root, $sce) ->
  self.selectedProduct = undefined
  self.itemIndex = -1
  self.trustedDescription = '';

  updateDescription = () ->
    self.trustedDescription = $sce.trustAsHtml(self.selectedProduct.description)

  self.searchKey = ($event) ->
    # right arrow
    return self.showNext() if $event.keyCode == 39
    # left arrow
    return self.showPrevious() if $event.keyCode == 37

  root.$on('openProductModal', (event, item) ->
    self.selectedProduct = item
    updateDescription()

    self.itemIndex = root.articles.findIndex((art) -> art.id == item.id)
    angular.element('#poductModal').modal('show')
  )

  self.showNext = () ->
    self.itemIndex = (self.itemIndex + 1) % root.articles.length
    self.selectedProduct = root.articles[self.itemIndex]
    updateDescription()

  self.showPrevious = () ->
    if self.itemIndex == 0
      self.itemIndex = root.articles.length - 1
    else
      self.itemIndex = self.itemIndex - 1

    self.selectedProduct = root.articles[self.itemIndex]
    updateDescription()

])