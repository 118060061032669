angular.module('app.controllers').controller('ProductModalCtrl', [
  '$scope',
  '$rootScope',
  '$sce',
  function(self,
  root,
  $sce) {
    var updateDescription;
    self.selectedProduct = void 0;
    self.itemIndex = -1;
    self.trustedDescription = '';
    updateDescription = function() {
      return self.trustedDescription = $sce.trustAsHtml(self.selectedProduct.description);
    };
    self.searchKey = function($event) {
      if ($event.keyCode === 39) {
        // right arrow
        return self.showNext();
      }
      if ($event.keyCode === 37) {
        // left arrow
        return self.showPrevious();
      }
    };
    root.$on('openProductModal',
  function(event,
  item) {
      self.selectedProduct = item;
      updateDescription();
      self.itemIndex = root.articles.findIndex(function(art) {
        return art.id === item.id;
      });
      return angular.element('#poductModal').modal('show');
    });
    self.showNext = function() {
      self.itemIndex = (self.itemIndex + 1) % root.articles.length;
      self.selectedProduct = root.articles[self.itemIndex];
      return updateDescription();
    };
    return self.showPrevious = function() {
      if (self.itemIndex === 0) {
        self.itemIndex = root.articles.length - 1;
      } else {
        self.itemIndex = self.itemIndex - 1;
      }
      self.selectedProduct = root.articles[self.itemIndex];
      return updateDescription();
    };
  }
]);
